<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/profile"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.profileEdit.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ODNProfileForm @saved="onSaved" @cancel="onCancel" />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from '@ionic/vue';

import ODNProfileForm from '@c/odn-profile-form';

export default {
  name: 'ProfileEdit',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ODNProfileForm,
  },
  methods: {
    async onSaved() {
      this.$router.replace('/tabs/profile');
    },
    onCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
