<template>
  <form @submit.prevent="onSave">
    <ODNForm>
      <!-- Lastname -->
      <ODNFormField
        :label="$t('fields.lastname.label')"
        label-position="floating"
      >
        <ion-input v-model="form.lastname"></ion-input>
      </ODNFormField>

      <!-- Firstname -->
      <ODNFormField
        :label="$t('fields.firstname.label')"
        label-position="floating"
      >
        <ion-input v-model="form.firstname"></ion-input>
      </ODNFormField>

      <!-- Password -->
      <ODNFormField
        :label="$t('fields.password.label')"
        label-position="floating"
      >
        <ion-input v-model="form.password" type="password"></ion-input>
      </ODNFormField>

      <!-- Password Confirmation -->
      <ODNFormField
        :label="$t('fields.passwordConfirm.label')"
        label-position="floating"
        :error="
          isSubmitted && errors.passwordConfirm
            ? $t('errors.passwordConfirm')
            : null
        "
      >
        <ion-input v-model="form.passwordConfirm" type="password"></ion-input>
      </ODNFormField>

      <!-- Avatar -->
      <ODNUploadImage :title="$t('fields.avatar.label')" v-model="avatar" />

      <!-- Actions -->
      <div class="odn-mat-16">
        <ion-button
          type="submit"
          color="primary"
          :disabled="saving"
          expand="block"
        >
          {{ $t('buttons.validate') }}
          <ion-icon slot="end" :icon="icons.checkmark"></ion-icon>
        </ion-button>
      </div>
    </ODNForm>
  </form>
</template>

<script>
import { mapState } from 'vuex';

import { IonInput, IonButton, IonIcon, toastController } from '@ionic/vue';
import { checkmark } from 'ionicons/icons';

import ODNForm from '@c/odn-form.vue';
import ODNFormField from '@c/odn-form-field.vue';
import ODNUploadImage from '@c/odn-upload-image.vue';

import APIService from '@s/api.service';

export default {
  components: {
    IonInput,
    IonButton,
    IonIcon,
    ODNForm,
    ODNFormField,
    ODNUploadImage,
  },
  data() {
    return {
      form: {},
      avatar: {},
      isSubmitted: false,
      loading: false,
      saving: false,
      icons: {
        checkmark,
      },
    };
  },
  computed: {
    ...mapState('auth', ['authData']),
    errors() {
      return {
        passwordConfirm:
          this.form.password &&
          this.form.passwordConfirm !== this.form.password,
      };
    },
  },
  created() {
    this.fetchUser();
  },
  emits: ['saved', 'cancel'],
  methods: {
    async fetchUser() {
      this.loading = true;
      try {
        const { lastname, firstname, avatar } = (
          await APIService.get(`/users/${this.authData.userId}`)
        ).data;
        this.form = {
          lastname,
          firstname,
        };
        if (avatar) {
          this.avatar.preview = avatar;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.profile.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async onSave() {
      this.isSubmitted = true;

      const hasErrors = Object.values(this.errors).some(e => e);

      if (hasErrors) return;

      this.saving = true;

      try {
        await APIService.put(`/users/${this.authData.userId}`, this.form);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.profile.put.error'),
          color: 'danger',
          duration: 2000,
        });
        toast.present();
      }

      // 2. Avatar upload
      if (this.avatar.file) {
        const formData = new FormData();
        formData.append('file', this.avatar.file);
        await APIService.upload(
          `/users/${this.authData.userId}/avatar`,
          formData
        );
      }

      this.saving = false;

      const toast = await toastController.create({
        message: this.$t('messages.profile.put.success'),
        color: 'success',
        duration: 2000,
      });
      toast.present();

      this.$emit('saved', this.userId);
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
